import { useEffect, useRef } from "react"

const isClient = typeof document !== "undefined"

export default function useEventListener(
  eventType,
  handler,
  { enabled = isClient, target = isClient && document } = {}
) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  })

  useEffect(() => {
    if (!enabled) return null

    const internalHandler = e => handlerRef.current(e)

    target.addEventListener(eventType, internalHandler)

    return () => {
      target.removeEventListener(eventType, internalHandler)
    }
  }, [eventType, enabled, target])
}
