import { useState } from "react"
import * as PropTypes from "prop-types"
import styled from "styled-components"
import useEventListener from "hooks/useEventListener"
import PhoneButton from "components/styled/Buttons/PhoneButton"
import TopPhoneButton from "components/styled/Buttons/TopPhoneButton"

const IconCall = styled(TopPhoneButton)`
  display: flex;
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: none;
  }
  &.floating {
    color: white;
    position: fixed;
    top: 50px;
    right: 38px;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    box-shadow: 0 2px 18px 0 rgba(255, 69, 151, 0.7);
    margin-top: -15px;
    transition: background-color 0.25s ease-out;
    background-color: ${({ theme }) => theme.colors.deepPink};
    align-items: center;
    justify-content: center;
  }
`

const TopPhone = styled(PhoneButton)`
  display: none;
  @media ${({ theme }) => theme.breakpoints.lg} {
    display: block;
  }
`

const isServer = typeof window === "undefined"

export default function CallButton(props) {
  const { floating, white, grayCta, handleClick } = props
  const [scroll, setScroll] = useState(false)

  const handleScroll = () => setScroll(window.scrollY > 100)

  useEventListener("scroll", handleScroll, {
    enabled: !isServer && floating,
    target: !isServer && window
  })

  return (
    <>
      <IconCall className={scroll ? "floating" : ""} white={white} onClick={() => handleClick(scroll)} />
      <TopPhone onClick={handleClick} grayCta={grayCta} />
    </>
  )
}

CallButton.propTypes = {
  grayCta: PropTypes.bool.isRequired,
  floating: PropTypes.bool.isRequired,
  white: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
}
